
/*--------------- BASE CSS --------------- */
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: var(--font) !important;
  background: #222222 !important;
}
ul{
  list-style: none;
}

a{
  text-decoration: none;
  cursor: pointer;
  color: white;
}
a:hover{
  color: white;
}

img {
  max-width: 100%;
  height: auto;
  padding: 0 20px 0 20px;
}
ion-icon {
  color: white;
}

.whitespace {
  width: 100%;
  height: 100px;
}
.alter-whitespace{
  width: 100%;
  height: 50px;
}
/* width */
::-webkit-scrollbar {
width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
box-shadow: inset 0 0 5px #222222; 
}

/* Handle */
::-webkit-scrollbar-thumb {
background: #e8eef1; 
border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: #b30000; 
}
/*--------------- VARIABLE CSS --------------- */
:root{

  /*--------------- COLORS --------------- */
  --main-color: #fff;
  --secondary-color:#343A40;
  --accent-color: rgba(255, 255, 255, 0.75);

  /*--------------- FONTS --------------- */
  --font: "Roboto Mono" !important;
}


/*--------------- typewriter effect --------------- */
.line {
  width: 24em;
  top: 50%;
  margin: auto;
  border-right: 2px solid rgba(255, 255, 255, 0.75);
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  transform: translateY(-50%);
}

.anim-typewriter {
  animation: typewriter 4s steps(40) 1s 1 normal both,
  blinkTextCussor 500ms steps(40) infinite normal;
}
.anim-typewriter1 {
  animation: typewriter 4s steps(40) 5s 1 normal both,
  blinkTextCussor 500ms steps(40) infinite normal;
}

@keyframes typewriter {
  from {
        width: 0;
  }
  to {
        width: 18em;
  }
}


@keyframes blinkTextCussor {
  from {
        border-right-color: rgba(255, 255, 255, 0.75);
  }
  to {
        border-right-color: transparent;
  }
}


/*--------------  intro ---------------------*/
.intro{
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: #161616;
  transition: 1s;
}
.logo-header{
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}
.logo1{
  font-size: 100px;
  position: relative;
  display: inline-block;
  bottom: -20px;
  opacity: 0;
}
.logo1.active{
  bottom: 0;
  opacity: 1;
  transition: ease-in-out 0.5s;
}
.logo1.fade{
  bottom: 150px;
  opacity: 0;
  transition: ease-in-out 0.5s;
}

/*--------------- navigation --------------- */
.menu-open {
color: #fff;
}

.menu-open,
.menu-close {
position: absolute;
top: 0;
right: 0;
padding: 40px;
font-size: 20px;
cursor: pointer;
}

.socials {
position: absolute;
bottom: 0;
left: 0;
margin: 40px 100px;
}

.socials a span {
text-transform: uppercase;
margin: 0 20px;
letter-spacing: 0px;
  color: #101010;
}
.socials a span:hover{
  text-decoration: none;
}

.nav-container {
position: fixed;
left: -100%;
width: 100%;
height: 100vh;
background: #fff;
  z-index: 1000;
  overflow: hidden;

}

.menu__item {
position: relative;
padding: 0 6vw;
}
.menu__item .menu__item-link{
  text-decoration: none;
}

.menu__item .menu__item-link:hover{
  color: var(--secondary-color);
}
.menu__item-link {
display: inline-block;
cursor: pointer;
position: relative;
transition: opacity 0.4s;
color: var(--secondary-color);
}

.menu__item-link::before {
all: initial;
counter-increment: menu;
position: absolute;
bottom: 60%;
left: 0;
pointer-events: none;
}

.menu__item-link:hover {
transition-duration: 0.1s;
opacity: 0;
}

.menu__item-img {
z-index: 2;
pointer-events: none;
position: absolute;
height: 12vh;
max-height: 600px;
opacity: 0;
left: 8%;
top: 10%;
transform: scale(0);
}

.menu__item-link:hover + .menu__item-img {
opacity: 1;
transform: scale(1);
transition: all 0.4s;
}

.menu {
padding: 10vh 0 25vh;
--offset: 20vw;
--move-initial: calc(-25% + var(--offset));
--move-final: calc(-50% + var(--offset));
font-size: 7vw;
}

.scroll-container {
  overflow: hidden;
  width: 100%;
}

.scroll-content {
  display: flex;
  animation: marquee 30s linear infinite; /* Adjust the duration as needed */
}

.scroll-content img {
  width: 200px; /* Set a fixed width for the images */
  height: 160px; /* Set a fixed height for the images */
  margin-right: 20px; /* Add spacing between images */
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-200%);
  }
}

.marquee {
position: absolute;
top: 0;
left: 0;
overflow: hidden;
color: rgb(214, 214, 214);
pointer-events: none;
}

.marquee__inner {
display: flex;
position: relative;
opacity: 0;
transition: all 0.1s;
transform: translateX(60px);
}

.menu__item-link:hover ~ .marquee .marquee__inner {
opacity: 1;
transform: translateX(0px);
transition-duration: 0.4s;
  
}

.menu__item-link,
.marquee span {
white-space: nowrap;
font-size: 7vw;
padding: 0 1vw;
line-height: 1.15;
}

.marquee span {
font-style: italic;
}


/*--------------- hero section --------------- */
.hero {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #222222;
}

.hero h1 {
  font-weight: lighter;
  text-align: center;
  letter-spacing: -2px;
  line-height: 58px;
  color: #fff;
}

/*--------------- scroll down icon animation --------------- */
.scroll-down {
  position: absolute;
  left: 50%;
  bottom: 100px;
  display: block;
  text-align: center;
  font-size: 20px;
  z-index: 0;
  text-decoration: none;
  text-shadow: 0;
  width: 13px;
  height: 13px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  animation: fade_move_down 3s cubic-bezier(0.19, 1, 0.22, 1) infinite;
}

@keyframes fade_move_down {
  0% {
        transform: translate(0, -20px) rotate(45deg);
        opacity: 0;
  }
  50% {
        opacity: 1;
  }
  100% {
        transform: translate(0, 20px) rotate(45deg);
        opacity: 0;
  }
}

/*--------------- projects section --------------- */

.hero-content h1{
  color: var(--accent-color);
}
.cursor, .cursors {
  position: absolute;
  background: white;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  z-index: 1;
  transition: 0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
      0.2s cubic-bezier(0.75, -1.27, 0.3, 2.33) opacity;
  pointer-events: none;
  transform: scale(0.8);
}

.cursor::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  background-image: url("https://raw.githubusercontent.com/TianMeds/image--stocks-for-coding/main/Cursor.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 100%;
  opacity: 0;
}

.cursors::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  background-image: url("https://raw.githubusercontent.com/TianMeds/image--stocks-for-coding/main/Cursors.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 100%;
  opacity: 0;
}

.cursor.active, .cursors.active {
  opacity: 1;
  transform: scale(12);
}

.cursor.active::before, .cursors.active::before{
  opacity: 1;
}

.cursor-follower, .cursor-followers {
  position: absolute;
  background: rgba(255, 255, 255, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 100%;
  z-index: 1;
  transition: 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
      0.4s cubic-bezier(0.75, -1.27, 0.3, 2.33) opacity;
  pointer-events: none;
  transform: translate(4px, 4px);
}

.cursor-follower.active {
  opacity: 0.3;
  transform: scale(0);
}

h6 {
  text-align: center;
  color: #fff;
}

.vertical {
  margin-top: 40px;
  border-left: 1px solid #fff;
  height: 80px;
  position: absolute;
  left: 50%;
}
.previous{
  display: inline-block;
  padding: 8px 16px;
  background-color: #f1f1f1;
  color: black;
  font-size: 20px;
}
.previous:hover{
  text-decoration: none;
  background-color: #ddd;
  color: black;
}
.round{
  border-radius: 50%;
}


.project {
  height: 500px;
}
.cert,
.project1:hover,
.project2:hover,
.project3:hover,
.project4:hover,
.project5:hover,
.project6:hover,
.project7:hover,
.project8:hover,
.project9:hover,
.project10:hover,
.project11:hover,
.project12:hover,
.project13:hover{
  opacity: 0.8;
  transform: scale(1.2);
  filter: grayscale(0);
}
.cert,
.project1::after,
.project2::after,
.project3::after,
.project4::after,
.project5::after,
.project6::after,
.project7::after,
.project8::after,
.project9::after,
.project10::after,
.project11::after,
.project11::after,
.project12::after,
.project13::after{
  transform: translateX(-1.75rem);
}

.project1 {
  background: url(https://bit.ly/3VfY87l) no-repeat 50% 50%;
  background-size: cover;
  opacity: 0.8;
  transition: all 0.5s cubic-bezier(0.25, 1, 0.3, 1);
  transform-origin: 90% center;
  filter: grayscale(100%);
}

.project1::after {
  content: "Minimalist Portfolio.";
  font-family: "Roboto Mono";
  font-size: 36px;
  position: relative;
  color: #fff;
  display: block;
  top: 8%;
  left: -60%;
  right: 0;
  bottom: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.25, 1, 0.3, 1);
}

.project2 {
  background: url(https://bit.ly/3SGtfXO) no-repeat 50% 50%;
  background-size: cover;
  opacity: 0.8;
  transition: all 0.5s cubic-bezier(0.25, 1, 0.3, 1);
  transform-origin: 90% center;
  filter: grayscale(100%);
}

.project2::after {
  content: "Recipe Website.";
  font-family: "Roboto Mono";
  font-size: 36px;
  position: relative;
  color: #fff;
  display: block;
  top: 80%;
  left: 90%;
  right: 0;
  bottom: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.25, 1, 0.3, 1);
}

.project3 {
  background: url(https://bit.ly/3TeCGhd) no-repeat 50% 50%;
  background-size: cover;
  opacity: 0.8;
  transition: all 0.5s cubic-bezier(0.25, 1, 0.3, 1);
  transform-origin: 90% center;
  filter: grayscale(100%);
}

.project3::after {
  content: "Capstone Generator.";
  font-family: "Roboto Mono";
  font-size: 36px;
  position: relative;
  color: #fff;
  display: block;
  top: 80%;
  left: -50%;
  right: 0;
  bottom: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.25, 1, 0.3, 1);
}

.project4 {
  background: url(https://bit.ly/3RD84ED) no-repeat 50% 50%;
  background-size: cover;
  opacity: 0.8;
  transition: all 0.5s cubic-bezier(0.25, 1, 0.3, 1);
  transform-origin: 90% center;
  filter: grayscale(100%);
}

.project4::after {
  content: "QR Generator";
  font-family: "Roboto Mono";
  font-size: 36px;
  position: relative;
  color: #fff;
  display: block;
  top: 10%;
  left: 90%;
  right: 0;
  bottom: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.25, 1, 0.3, 1);
}
.project5 {
  background: url(https://bit.ly/3RRz5Vf) no-repeat 50% 50%;
  background-size: cover;
  opacity: 0.8;
  transition: all 0.5s cubic-bezier(0.25, 1, 0.3, 1);
  transform-origin: 90% center;
  filter: grayscale(100%);
}

.project5::after {
  content: "Fiverr Portfolio Copy";
  font-family: "Roboto Mono";
  font-size: 36px;
  position: relative;
  color: #fff;
  display: block;
  top: 8%;
  left: -60%;
  right: 0;
  bottom: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.25, 1, 0.3, 1);
}
.project6 {
  background: url(https://bit.ly/3CvyPqn) no-repeat 50% 50%;
  background-size: cover;
  opacity: 0.8;
  transition: all 0.5s cubic-bezier(0.25, 1, 0.3, 1);
  transform-origin: 90% center;
  filter: grayscale(100%);
}

.project6::after {
  content: "2nd Portfolio Fiverr";
  font-family: "Roboto Mono";
  font-size: 36px;
  position: relative;
  color: #fff;
  display: block;
  top: 10%;
  left: 90%;
  right: 0;
  bottom: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.25, 1, 0.3, 1);
}
.project7 {
  background: url(https://bit.ly/3EfjyLt) no-repeat 50% 50%;
  background-size: cover;
  opacity: 0.8;
  transition: all 0.5s cubic-bezier(0.25, 1, 0.3, 1);
  transform-origin: 90% center;
  filter: grayscale(100%);
}

.project7::after {
  content: "Discord Bot";
  font-family: "Roboto Mono";
  font-size: 36px;
  position: relative;
  color: #fff;
  display: block;
  top: 80%;
  left: -50%;
  right: 0;
  bottom: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.25, 1, 0.3, 1);
}

.project8 {
  background: url(https://bit.ly/3rxxPf4) no-repeat 50% 50%;
  background-size: cover;
  opacity: 0.8;
  transition: all 0.5s cubic-bezier(0.25, 1, 0.3, 1);
  transform-origin: 90% center;
  filter: grayscale(100%);
}

.project8::after {
  content: "Research Generator";
  font-family: "Roboto Mono";
  font-size: 36px;
  position: relative;
  color: #fff;
  display: block;
  top: 10%;
  left: 90%;
  right: 0;
  bottom: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.25, 1, 0.3, 1);
}
.project9 {
  background: url(https://raw.githubusercontent.com/TianMeds/image--stocks-for-coding/main/Calculator_Mockup.jpg) no-repeat 50% 50%;
  background-size: cover;
  opacity: 0.8;
  transition: all 0.5s cubic-bezier(0.25, 1, 0.3, 1);
  transform-origin: 90% center;
  filter: grayscale(100%);
}

.project9::after {
  content: "Calculator App.";
  font-family: "Roboto Mono";
  font-size: 36px;
  position: relative;
  color: #fff;
  display: block;
  top: 8%;
  left: -60%;
  right: 0;
  bottom: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.25, 1, 0.3, 1);
}

.project10 {
  background: url(https://raw.githubusercontent.com/TianMeds/image--stocks-for-coding/main/Mockup-Digital-Clock.jpg) no-repeat 50% 50%;
  background-size: cover;
  opacity: 0.8;
  transition: all 0.5s cubic-bezier(0.25, 1, 0.3, 1);
  transform-origin: 90% center;
  filter: grayscale(100%);
}

.project10::after {
  content: "Digital Clock.";
  font-family: "Roboto Mono";
  font-size: 36px;
  position: relative;
  color: #fff;
  display: block;
  top: 80%;
  left: 90%;
  right: 0;
  bottom: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.25, 1, 0.3, 1);
}

.project11 {
  background: url(https://raw.githubusercontent.com/TianMeds/image--stocks-for-coding/main/Todo-ListMockup.jpg) no-repeat 50% 50%;
  background-size: cover;
  opacity: 0.8;
  transition: all 0.5s cubic-bezier(0.25, 1, 0.3, 1);
  transform-origin: 90% center;
  filter: grayscale(100%);
}

.project11::after {
  content: "Todo List App.";
  font-family: "Roboto Mono";
  font-size: 36px;
  position: relative;
  color: #fff;
  display: block;
  top: 80%;
  left: -50%;
  right: 0;
  bottom: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.25, 1, 0.3, 1);
}
.project12 {
  background: url(https://raw.githubusercontent.com/TianMeds/image--stocks-for-coding/main/Mockup-Weather.jpg) no-repeat 50% 50%;
  background-size: cover;
  opacity: 0.8;
  transition: all 0.5s cubic-bezier(0.25, 1, 0.3, 1);
  transform-origin: 90% center;
  filter: grayscale(100%);
}

.project12::after {
  content: "Weather Application.";
  font-family: "Roboto Mono";
  font-size: 36px;
  position: relative;
  color: #fff;
  display: block;
  top: 80%;
  left: 90%;
  right: 0;
  bottom: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.25, 1, 0.3, 1);
}
.project13 {
  background: url(https://raw.githubusercontent.com/TianMeds/image--stocks-for-coding/main/Homecity%20Mockup.jpg) no-repeat 50% 50%;
  background-size: cover;
  opacity: 0.8;
  transition: all 0.5s cubic-bezier(0.25, 1, 0.3, 1);
  transform-origin: 90% center;
  filter: grayscale(100%);
}

.project13::after {
  content: "Homecity.";
  font-family: "Roboto Mono";
  font-size: 36px;
  position: relative;
  color: #fff;
  display: block;
  top: 80%;
  left: 90%;
  right: 0;
  bottom: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.25, 1, 0.3, 1);
}
.image_div{
  min-height: 80vh;
  position: relative;
  overflow: hidden;
  padding: 1rem;
  display: flex;
  flex-direction: row;
}
.box{
  min-width: 800px;
  height: 500px;
  overflow: hidden;
  border-radius: .2rem;
  margin-right: 2rem;
}
.box img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1.05);
}
.box img:hover{
  transform: scale(1);
  transition: all .4s linear;
}
.project-link a{
  color: var(--main-color);
}
.project-link a:hover{
  color: var(--main-color);
}







/*--------------- footer section --------------- */
.collab {
  text-align: right;
}

.collab p {
  font-weight: lighter !important;
  margin-bottom: 20px;
  color: #fff;
}

.hr {
  background: rgba(255, 255, 255, 0.2);
  height: 1px;
}

.info h4 {
  font-size: 18px;
  font-weight: lighter;
}

.info p {
  color: grey;
  font-weight: lighter;
}
.info li {
  font-weight: lighter;
  color: #fff;
  font-size: 18px;
  padding-left: 20px;
}
#personal a {
  color: var(--main-color);
}

#fb::before {
  display: inline-block;
  content: '';
  border-radius: 100%;
  height: 4px;
  width: 4px;
  margin-right: 6px;
  background: #3b5998;
}


#ig::before {
  display: inline-block;
  content: '';
  border-radius: 100%;
  height: 4px;
  width: 4px;
  margin-right: 6px;
  background: #fcaf45;
}


#gh::before {
  display: inline-block;
  content: '';
  border-radius: 100%;
  height: 4px;
  width: 4px;
  margin-right: 6px;
  background: #55acee;
}


#ln::before {
  display: inline-block;
  content: '';
  border-radius: 100%;
  height: 4px;
  width: 4px;
  margin-right: 6px;
  background: #c4302b;
}
#personal{
  color: #fff;
}

#media, #address {
  text-align: right;
  color: #fff;
}

#media ul {
  list-style: none;
}

#media ul li {
  display: inline-block;
}
#address a{
  color: var(--main-color);
}

/*--------------- work page --------------- */

.hero-content {
  margin-left: 0%;
}

.hero-content p {
  font-weight: 300;
  line-height: 36px;
  color: grey;
}

.hero-content h6 {
  text-align: left;
}
.hero-content h3{
  color: var(--main-color);
}
/*---------------about page --------------- */

.about {
  height: 500px;
  margin-top: 120px;
}
.marginRow {
  margin-top: 50px;
}

.box-services span {
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
  transform: scale(0);
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;		
  transition: all 0.4s;
}
.box-services p {
padding-top: 30px;
  font-size: 13px;
  color: #717982;
}
#blog{
  color: var(--main-color);
}
#blog-btn{
  color: var(--main-color);
}
#blog-btn h4{
  font-size: 16px;
  display: inline-block;
  position: relative;
}
#blog-btn h4::after{
content: '';
position: absolute;
width: 100%;
transform: scaleX(0);
height: 2px;
bottom:0;
left: 0;
background-color: var(--main-color);
transform-origin: bottom right;
transition: transform 0.25s ease-out;
}
#blog-btn h4:hover::after{
  transform: scaleX(1);
  transform-origin: bottom left;
}
#blog-btn ion-icon{
  font-size: 16px;
  position: relative;
  top: 2px;
}
.service{
  padding-top: 20px;
  color: var(--main-color);
  margin-left: 20px;
}
.award-head, .award-body{
  color:var(--main-color);
}
.award-head{
  font-size: 1.3rem;
}
td{
  color: var(--main-color);
}
.cert{
  color: var(--main-color);
}
.cert:hover{
  color: var(--main-color);
  text-decoration: none;
}
.cert::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.cert:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
#view{
  max-width:150px;
  margin-left: 10px;
  padding: 20px 30px;
  background: transparent;
  color: #dfdfdf;
  font-size: 14px;
  letter-spacing: 2px;
  border: 3px solid #dfdfdf;
}

#view:hover {
  background: #dfdfdf;
  color: #101010;
  text-decoration: none;
}



/*--------------- contact form --------------- */

#contact-form {
  margin: 5% 1.4%;
}

#contact-form ul {
  list-style: none;
  border-radius: 5px;
  margin-bottom: 40px;
}

#contact-form li {
  padding: 10px;
}

#contact-form li:last-of-type {
  border-bottom: none;
}

#contact-form label {
  display: block;
  font-size: .8em;
  color: #999;
  padding-left: 5px;
}

#contact-form input, #contact-form textarea {
  width: 100%;
  padding: 5px;
  border: none;
  resize: vertical;
  background: transparent;
  color: #fff;
  font-weight: bolder;
}

input:focus {
  outline: none;
  border: none;
}

textarea:focus {
  outline: none;
  border: none;
}

.textarea {
  border-bottom: 1px solid #dfdfdf;
}
#dropdown{
  display: block;
  width: 100%;
  height: 40px;
  padding-left: 1rem;
  border-radius: 0.50rem;
  border: 1px solid black;
  
}
select:invalid{
  color: black;
}
option{
  color: black;
}

#send{
  max-width:150px;
  margin-left: 40px;
  padding: 20px 30px;
  background: transparent;
  color: #dfdfdf;
  font-size: 14px;
  letter-spacing: 2px;
}

#send:hover {
  background: #dfdfdf;
  color: #101010;
}

.error{
  color: #d9302a;
}
#success{
  color: #129f62;
  margin-left: 55px;
}

/*--------------- project page --------------- */
.project-wrapper{
  max-width: 100%;
}

.prev, .next p {
  color: grey;
}

.prev p {
  text-align: left;
}

.next p {
  text-align: right;
}
@media(max-width: 1024px){
  
  .cert::after,.project1::after, .project2::after, .project3::after, .project4::after, 
  .project5::after, .project6::after, .project7::after, .project8::after, 
  .project9::after,.project10::after, .project11::after, .project12::after, .project13::after{
        display: none;
  }
  .project {
        width: 90% !important;
        margin: 14px auto;
  }
  .image_div{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow-x: hidden;
  }
  .box{
        margin-bottom: 2rem;
  }
  .box img{
        transform: scale(1);
  }

}

@media(max-width: 768px) {

  @keyframes typewriter {
        from {
              width: 0;
        }
        to {
              width: 17em;
        }
  }
  .logo1{
font-size: 50px;
}
  .header h1 {
        font-size: 26px;
        color: #fff;
  }
  .project {
        width: 90% !important;
        margin: 14px auto;
  }
  .cert:hover,
  .project1:hover,
  .project2:hover,
  .project3:hover,
  .project4:hover,
  .project5:hover,
  .project6:hover,
  .project7:hover,
  .project8:hover,
  .project9:hover,
  .project10:hover,
  .project11:hover,
  .project12:hover,
  .project13:hover{
  transform: scale(1);
  }
  .cursor{
        display: none;
  }
  .cursor-follower{
        display: none;
  }
  .image_div{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow-x: hidden;
  }
  .box{
        padding-bottom: 2rem;
        min-width: 480px;
        margin: auto;
  }

  .vertical {
        display: none;
  }

  .collab, #personal, #media, #address {
        text-align: center;
  }

  .info ul {
        margin: 0 0 0 -22px;
        padding: 0;
  }
  .hero-content {
        padding-top: 50px;
        width: 96%;
        margin: 0 auto;
  }
  #contact-form {
        margin: 5% -8%;
        width: 98%;
  }
}
@media(max-width:480px){

  .header h1 {
        font-size: 16px;
  }
  

  select:invalid{
       font-size: 12px;
  }
  .cert,
  .project1,
  .project2,
  .project3,
  .project4,
  .project5,
  .project6,
  .project7,
  .project8,
  .project9,
  .project10,
  .project11,
  .project12,
  .project13{
        filter: grayscale(0);
  }
  .box{
        min-width: 100px;
        margin: auto;
  }
  .box img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1.05);
  }

}